@charset "UTF-8";
:root {
  --logo-url: url("../../img/QualtricsXM-Logo.png");
  --white: var(--white-100);
  --header-active-menu-bg: var(--custom-bg-header, var(--white-100));
  --header-link-color: var(--default-text-color);
  --header-link-color-hover: var(--default-primary-color, var(--gray-700));
  --modal-header-text-color: var(--gray-1000);
  --modal-header-background: var(--white);
  --modal-divider-color: var(--gray-500);
  --focus-outline-color: var(--action-1000);
  --focus-background-color: var(--action-700);
  --primary-bg: var(--custom-bg-primary, var(--gray-100));
  --dashboard-bg: var(--primary-bg);
  --button-hover-background: var(--action-800);
  --button-hover-border-color: var(--action-800);
  --button-hover-text-color: var(--white);
}

/*
	██ ███    ███ ██████   ██████  ██████  ████████  █████  ███    ██ ████████     ██
	██ ████  ████ ██   ██ ██    ██ ██   ██    ██    ██   ██ ████   ██    ██        ██
	██ ██ ████ ██ ██████  ██    ██ ██████     ██    ███████ ██ ██  ██    ██        ██
	██ ██  ██  ██ ██      ██    ██ ██   ██    ██    ██   ██ ██  ██ ██    ██
	██ ██      ██ ██       ██████  ██   ██    ██    ██   ██ ██   ████    ██        ██

	This file should include nothing but color rules!!!

	1. ".dark-mode-class-placeholder" is replaced on backend for scope specific class appending ' &', where appropriate
	2. "html {" is replaced with scope specific element, so everything should be inside html rule
*/
:root {
  --black-50-percent: rgba(0, 0, 0, 0.5);
  --white-50-percent: rgba(255, 255, 255, 0.5);
  --default-text-color: var(--gray-1000);
  --default-primary-color: var(--action-700);
  --active-text-hover-color: var(--action-600);
  --loading-spinner-background: var(--white);
}

.select2-container-active .select2-choice, .select2-container-active .select2-choices, .dropdown-input.option-chicklet.open, textarea:focus, input:focus, select:focus, .br-color-dropdown button:focus, .dropdown.fake-select button:not(.ignore-dropdown-style):focus,
.form-control:focus, .input:focus, .fake-select > button:not(.ignore-dropdown-style):focus, .dropdown-input.dropdown:focus, .discussion-submission:focus, textarea::placeholder, input::placeholder, select::placeholder, .br-color-dropdown button::placeholder, .dropdown.fake-select button:not(.ignore-dropdown-style)::placeholder,
.form-control::placeholder, .input::placeholder, .fake-select > button:not(.ignore-dropdown-style)::placeholder, .dropdown-input.dropdown::placeholder, .discussion-submission::placeholder, tags-input .tags.focused, a.btn-icon-small:hover, a.btn-icon-small:focus,
.br-selected-metric .btn-icon-small.selected, a.btn-icon:hover, a.btn-icon:focus, button.btn-icon:hover, button.btn-icon:focus, .br-brand-border-four, .widgetTypeElement.ui-draggable-dragging, .action-border {
  border-color: var(--default-primary-color);
}

.audit-mode speech-bubble article p:hover, .table tr:hover,
.cases-table tr:hover,
.br-grid .slick-row:hover,
.br-log-grid .slick-row:hover, .hover-bg-cxs-brand-100:hover, .audit-mode speech-bubble article p.hover, .table tr.hover,
.cases-table tr.hover,
.br-grid .hover.slick-row,
.br-log-grid .hover.slick-row, .hover.hover-bg-cxs-brand-100 {
  color: var(--default-text-color);
  background-color: var(--action-200);
}

.dropdown-menu:not(.disabled):not(.read-only) .active > a, .dropdown-menu:not(.disabled):not(.read-only) .active > .dropdown-item, .dropdown-menu:not(.disabled):not(.read-only) .active.dropdown-item, .dropdown-menu:not(.disabled):not(.read-only) .dropdown-item:hover, .dropdown-menu:not(.disabled):not(.read-only) .dropdown-item:focus, .dropdown-menu:not(.disabled):not(.read-only) li > button:hover, .dropdown-menu:not(.disabled):not(.read-only) li > a:hover, .dropdown-menu:not(.disabled):not(.read-only) li > a.focus-within, .dropdown-menu:not(.disabled):not(.read-only) li > span.br-hierarchy-node-toggle:focus, .dropdown-menu:not(.disabled):not(.read-only) li > a:focus-within, .dropdown-menu:not(.disabled):not(.read-only) li div:focus > .dropdown-item, .dropdown-menu:not(.disabled):not(.read-only) .active > button:hover, .dropdown-menu:not(.disabled):not(.read-only) .active > a:hover, .dropdown-menu:not(.disabled):not(.read-only) .active > a.focus-within, .dropdown-menu:not(.disabled):not(.read-only) .active > span.br-hierarchy-node-toggle:focus, .dropdown-menu:not(.disabled):not(.read-only) .active > a:focus-within, .dropdown-menu:not(.disabled):not(.read-only) .active div:focus > .dropdown-item, .dropdown-menu:not(.disabled):not(.read-only) li > div > button:hover, .dropdown-menu:not(.disabled):not(.read-only) li > div > a:hover, .dropdown-menu:not(.disabled):not(.read-only) li > div > a.focus-within, .dropdown-menu:not(.disabled):not(.read-only) li > div > span.br-hierarchy-node-toggle:focus, .dropdown-menu:not(.disabled):not(.read-only) li > div > a:focus-within, .dropdown-menu:not(.disabled):not(.read-only) li > div div:focus > .dropdown-item, #dsh-context-menu > li > a:hover,
#dsh-context-menu > li > a:focus,
#dsh-context-menu .open-menu > a, .action-hover:hover, tags-input .autocomplete .suggestion-item.selected, tags-input .autocomplete .suggestion-item.selected em, context-menu .expandable:hover, .hierarchy-item:focus-within, .hierarchy-item:hover, .hierarchy-item.focus-within, .node-hierarchy > li > a:hover, .node-hierarchy > li > a:hover > .br-hierarchy-node-name, .sub-menu-item:focus #actionOption a, .sub-menu-item:focus, .soft-selection {
  fill: var(--white);
  background-color: var(--default-primary-color);
  color: var(--white);
}

document-previewer .dex-left-pane:focus, .cb-preview-table-container .dex-left-pane:focus, tuning-suggestion-summary .dex-left-pane:focus, .highcharts-legend-item-hidden .highcharts-point:focus, .palette-color.open, textarea:focus, input:focus, select:focus, .br-color-dropdown button:focus, .dropdown.fake-select button:not(.ignore-dropdown-style):focus,
.form-control:focus, .input:focus, .fake-select > button:not(.ignore-dropdown-style):focus, .dropdown-input.dropdown:focus, .discussion-submission:focus, .toggle-switch:focus, .hierarchy-item:focus-within, .cloud-word:focus-visible, .cloud-word.highcharts-point-hover, .document-audio-player:focus-visible, .document-audio-player [tabindex="0"]:focus-visible, .outset-focus-indicator:focus-visible, .iframe-focus-bumber:focus + iframe, .kb-focusable-inset:focus, .slick-cell:focus, .slick-header-column:focus, dashboard-filters-edit a:focus,
.filter-date-picker .filter-time-text:focus, .filter-date-picker .filter-time-selector:focus, .kb-focusable:focus, a:focus, [uib-datepicker] [tabindex="0"]:focus, button:focus {
  outline: solid 2px var(--focus-outline-color);
  outline-offset: -2px;
}

.br-show-total .slick-row:hover, .br-widget-content .slick-row:hover,
.cb-preview-table tr:hover,
.br-widget-content .slick-header-column:hover,
.preview-tabe-view .slick-header-column:hover, .br-show-total .hover.slick-row, .br-widget-content .hover.slick-row,
.cb-preview-table tr.hover,
.br-widget-content .hover.slick-header-column,
.preview-tabe-view .hover.slick-header-column {
  background-color: var(--action-200);
  color: var(--default-text-color);
}

cb-sidebar-nav a:focus-visible {
  outline: solid 2px var(--gray-100);
  outline-offset: -2px;
}

.cxs-brand-primary {
  color: var(--default-primary-color);
}

.cxs-brand-primary-100 {
  color: var(--action-200);
}

.bg-cxs-brand-primary-100 {
  background: var(--action-200);
}

.cxs-brand-primary-200 {
  color: var(--action-300);
}

.bg-cxs-brand-primary-200 {
  background: var(--action-300);
}

.cxs-brand-primary-300 {
  color: var(--action-400);
}

.bg-cxs-brand-primary-300 {
  background: var(--action-400);
}

.cxs-brand-primary-500 {
  color: var(--default-primary-color);
}

.bg-cxs-brand-primary-500 {
  background: var(--default-primary-color);
}

.cxs-brand-primary-700 {
  color: var(--action-800);
}

.bg-cxs-brand-primary-700 {
  background: var(--action-800);
}

.cxs-brand-primary-900 {
  color: var(--action-1000);
}

.bg-cxs-brand-primary-900 {
  background: var(--action-1000);
}

.invert .cxs-brand-primary {
  color: var(--default-primary-color);
}
.invert .cxs-brand-primary-100 {
  color: var(--action-1000);
}
.invert .bg-cxs-brand-primary-100 {
  background: var(--action-1000);
}
.invert .cxs-brand-primary-200 {
  color: var(--action-800);
}
.invert .bg-cxs-brand-primary-200 {
  background: var(--action-800);
}
.invert .cxs-brand-primary-300 {
  color: var(--default-primary-color);
}
.invert .bg-cxs-brand-primary-300 {
  background: var(--default-primary-color);
}
.invert .cxs-brand-primary-500 {
  color: var(--white);
}
.invert .bg-cxs-brand-primary-500 {
  background: var(--white);
}
.invert .cxs-brand-primary-700 {
  color: var(--action-300);
}
.invert .bg-cxs-brand-primary-700 {
  background: var(--action-300);
}
.invert .cxs-brand-primary-900 {
  color: var(--action-200);
}
.invert .bg-cxs-brand-primary-900 {
  background: var(--action-200);
}

html {
  color: var(--gray-900);
}

a {
  color: var(--default-text-color);
}
a:hover {
  color: var(--active-text-hover-color);
}
a.inline {
  text-decoration: underline;
}
a.inline:hover {
  color: var(--action-500);
}

pre {
  background-color: var(--gray-200);
  color: var(--default-text-color);
}

fieldset[disabled] .btn-primary.active, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: var(--white);
}

#custom-expression-container {
  background-color: var(--gray-100);
}

.expression-chicklet.action-border {
  background-color: var(--gray-100);
}

.gridster-loaded .br-widget-box:not(.CONTENT):not(.bg-none) {
  background-color: var(--gray-100);
  outline-offset: -1px;
}

label {
  color: var(--default-text-color);
}

a:hover > label {
  color: var(--white);
}

.no-results {
  color: var(--default-text-color);
}

.help-block {
  color: var(--default-text-color);
}

.loader {
  border-top: 0.5rem solid var(--gray-200);
  border-right: 0.5rem solid var(--gray-200);
  border-bottom: 0.5rem solid var(--gray-200);
  border-left: 0.5rem solid var(--action-600);
}

tags-input .tags {
  background-color: var(--white);
}

.cg-busy-backdrop {
  opacity: 0.7 !important;
}

.CB.MAP .cg-busy-backdrop {
  opacity: 1 !important;
  background-color: var(--white);
}

.br-widget-header, .br-widget-caption {
  background-color: var(--white);
}

.br-widget-footer {
  background-color: var(--white);
  color: var(--default-text-color);
  border-bottom-left-radius: var(--widget-border-radius);
  border-bottom-right-radius: var(--widget-border-radius);
}

.demo-heatmap-footer {
  background-color: var(--white);
}

.br-widget-total, .br-widget-total-demo {
  color: var(--default-text-color);
}

.bg-none .br-widget-view, .bg-none .br-widget-footer {
  background: none !important;
}
.bg-none .br-widget-content .slick-header-column, .bg-none .br-widget-content .slick-row:not(:hover) {
  background: none !important;
}

.widget-image-export .bg-none {
  background-color: var(--gray-200) !important;
}

.gridster-loaded .br-widget-box:not(.CONTENT):not(.bg-none) {
  background: var(--white);
}

.br-widget-view {
  background-color: var(--white);
}

.hover-bg-cxs-brand:hover {
  background-color: var(--default-primary-color);
}

.gauge-chart metric-label {
  text-shadow: -1px 0 var(--gray-100), 0 1px var(--gray-100), 1px 0 var(--gray-100), 0 -1px var(--gray-100);
}

.hover-bg-cxs-brand-200:hover {
  background-color: var(--action-300);
}

.hover-bg-cxs-brand-300:hover {
  background-color: var(--action-400);
}

.hover-bg-cxs-brand-400:hover {
  background-color: var(--default-primary-color);
}

.hover-bg-cxs-brand-500:hover {
  background-color: var(--action-800);
}

.hover-bg-cxs-brand-600:hover {
  background-color: var(--action-1000);
}

@keyframes fadeOut {
  from {
    background-color: var(--action-200);
  }
  to {
    background-color: transparent;
  }
}
.brand-fade-out {
  animation-name: fadeOut;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

.soft-selection {
  outline: solid 2px var(--action-900);
}

a, .branded-link, .branded-link-wrapper a {
  color: var(--default-primary-color);
}
a:focus, a:hover, .branded-link:focus, .branded-link:hover, .branded-link-wrapper a:focus, .branded-link-wrapper a:hover {
  color: var(--active-text-hover-color);
}
a.deselected, .branded-link.deselected, .branded-link-wrapper a.deselected {
  color: var(--action-500);
}

.q-tabs .nav-link:focus, .q-tabs .nav-link:hover {
  color: var(--active-text-hover-color);
}

/*logo*/
.br-logo {
  box-sizing: border-box;
  background: var(--logo-url) no-repeat;
  background-size: contain;
  background-position: center;
}

.branded-link, .branded-link-wrapper a {
  color: var(--default-primary-color) !important;
}
.branded-link:focus, .branded-link:hover, .branded-link-wrapper a:focus, .branded-link-wrapper a:hover {
  color: var(--action-400) !important;
}

/*keyboard navigation*/
.kb-focusable:focus-visible, a:focus-visible, [uib-datepicker] [tabindex="0"]:focus-visible, button:focus-visible {
  z-index: 1;
}
.sub-menu-item:focus #actionOption a {
  background-clip: content-box;
}

.outset-focus-indicator:focus-visible {
  outline-offset: 0 !important;
}

.kb-focusable-border:focus-visible {
  border: solid 2px var(--action-900);
}
.kb-focusable-border:focus-visible#main-dashboard-controller {
  margin-bottom: 2px;
}
.kb-focusable-border:focus-visible .br-global-view {
  margin: -2px;
}
.kb-focusable-border:focus-visible .secondary-header {
  margin: -2px;
  margin-bottom: 0;
}
.kb-focusable-border:focus-visible .secondary-header + .br-global-view {
  margin-top: 0;
}

.cb-spine-row:focus-visible {
  stroke: var(--action-200);
}

a.labeled-checkbox:focus-within {
  outline: solid 2px var(--focus-outline-color) !important;
}

#bypass-block a, #bypass-modal-block a {
  background: none repeat scroll 0 0 var(--white);
  color: var(--gray-900);
}
#bypass-block a:focus, #bypass-modal-block a:focus {
  outline: solid 2px var(--focus-outline-color);
}

/*button*/
.btn-primary, .btn-primary:focus, .btn-primary[disabled], .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:active, .uib-datepicker-popup .uib-close, .uib-datepicker-popup .uib-close:focus, .uib-datepicker-popup .uib-close[disabled], .uib-datepicker-popup .uib-close[disabled]:focus, .uib-datepicker-popup .uib-close[disabled]:hover, .uib-datepicker-popup .uib-close[disabled]:active, .btn-info.active, .btn-info.active:focus, .btn-info.active[disabled], .btn-info.active[disabled]:focus, .btn-info.active[disabled]:hover, .btn-info.active[disabled]:active, .sign-in-page-container .btn-embedded-primary {
  background-color: var(--action-700);
  color: var(--white);
  border-color: var(--action-700);
}

.sign-in-page-container .btn-embedded-primary:hover, [uib-datepicker] .btn.active, [uib-datepicker] .btn.focused, [uib-datepicker] .btn:hover, .date-point-day.btn.active, .date-point-day.btn.focused, .date-point-day.btn:hover, .btn-group > .btn:not(.btn-selected):hover, .btn:not(.no-hover):hover {
  background-color: var(--button-hover-background);
  border-color: var(--button-hover-border-color);
  color: var(--button-hover-text-color);
  fill: var(--button-hover-text-color);
}

.btn:not(.no-hover):focus {
  outline: solid 2px var(--focus-outline-color);
  outline-offset: var(--default-outline-offset);
  position: relative;
  z-index: 1;
}
.btn:not(.no-hover):focus:hover:not(.btn-icon):not(.btn-selected):not(.btn-secondary) {
  background-color: var(--action-900);
  border-color: var(--action-900);
  color: var(--white);
}

.btn-group .btn:not(.no-hover).btn-selected:focus {
  background-color: var(--action-800);
  color: var(--white);
}

.btn-primary.btn-link, .btn-primary.btn-text, .uib-datepicker-popup .uib-close.btn-link, .uib-datepicker-popup .uib-close.btn-text, .btn-info.active.btn-link, .btn-info.active.btn-text {
  background-color: transparent;
  border-color: transparent;
  color: var(--default-primary-color);
}
.btn-primary.btn-link:hover, .btn-primary.btn-text:hover, .uib-datepicker-popup .uib-close.btn-link:hover, .uib-datepicker-popup .uib-close.btn-text:hover, .btn-info.active.btn-link:hover, .btn-info.active.btn-text:hover {
  background-color: var(--button-hover-background);
  border-color: var(--button-hover-border-color);
}
.btn-primary.btn-link:hover:focus, .btn-primary.btn-text:hover:focus, .uib-datepicker-popup .uib-close.btn-link:hover:focus, .uib-datepicker-popup .uib-close.btn-text:hover:focus, .btn-info.active.btn-link:hover:focus, .btn-info.active.btn-text:hover:focus {
  color: var(--button-hover-text-color);
}

a.nav-link {
  color: inherit;
}

ngb-datepicker-navigation .btn {
  background-color: var(--white);
  color: var(--action-700);
}
ngb-datepicker-navigation .btn.btn-link {
  background-color: transparent;
  border-color: transparent;
}
ngb-datepicker-navigation .btn.btn-link:hover, ngb-datepicker-navigation .btn.btn-link:focus {
  background-color: var(--button-hover-background);
  border-color: var(--button-hover-border-color);
}

.custom-day.range, .custom-day:hover {
  background-color: var(--default-primary-color);
  color: var(--white);
}

.custom-day.faded {
  background-color: var(--action-800);
}

checkbox-button .btn-secondary {
  border-color: transparent;
  color: var(--default-text-color);
}
checkbox-button .btn-secondary.btn:not(.no-hover):not(.btn-icon):not(.btn-selected):hover {
  background-color: var(--action-100);
  color: var(--action-800);
}
checkbox-button .btn-secondary.btn:not(.no-hover):not(.btn-icon):not(.btn-selected):focus {
  outline-offset: -2px;
}
checkbox-button .btn-secondary.btn:not(.no-hover):not(.btn-icon):not(.btn-selected):focus:hover {
  background-color: var(--action-100);
  color: var(--action-800);
}

[uib-datepicker] .btn.selected, .date-point-day.btn.selected {
  background-color: var(--action-900);
  color: var(--white);
  fill: var(--gray-200);
  border: 1px solid var(--action-900);
}

a.btn-link {
  color: var(--default-primary-color);
}

.btn-link.btn-selected.btn-secondary {
  background-color: var(--action-800);
  color: var(--white);
}

.uib-datepicker-current, .uib-datepicker-popup .uib-clear, .btn-info {
  color: var(--gray-900);
  border: 1px solid var(--gray-900);
  background: var(--white);
}

.pagination > li:not(.active):not(.disabled) > a:hover, .pagination > li:not(.active):not(.disabled) > span:hover, .pagination .page-item:not(.disabled) > .page-link > a:hover, .pagination .page-item:not(.disabled) > .page-link > span:hover {
  color: var(--action-800);
  background-color: var(--action-100);
  border: 1px solid var(--action-100);
}
.pagination > li:not(.active):not(.disabled) > a:focus, .pagination > li:not(.active):not(.disabled) > span:focus, .pagination .page-item:not(.disabled) > .page-link > a:focus, .pagination .page-item:not(.disabled) > .page-link > span:focus {
  color: var(--action-800);
  outline-color: var(--action-800);
}
.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  color: var(--white);
  background-color: var(--action-800);
  color: var(--white);
  border-color: var(--action-800);
  outline-color: var(--action-800);
}

/*dialogue, popup, modal*/
.header-link {
  color: inherit;
  padding: 8px;
}

.modal-header {
  color: var(--modal-header-text-color);
  background-color: var(--modal-header-background);
  border-bottom: 1px solid var(--modal-divider-color);
  height: 56px;
}
.modal-header cb-modal-close button {
  color: inherit;
}
.modal-header cb-modal-close button:focus, .modal-header cb-modal-close button:focus-visible {
  outline: solid 2px var(--action-800) !important;
  outline-offset: -1px !important;
}

.modal-body {
  background-color: var(--white);
}

.panel {
  background-color: var(--white);
}

.action-background, .bg-primary {
  color: var(--white);
  background-color: var(--action-800);
}

#primary-container, .main-content {
  background-color: var(--gray-200);
}

.br-dashboard-empty-image {
  color: var(--custom-bg-primary-contrast, var(--default-text-color));
}

.action-color {
  color: var(--action-600) !important;
}

.br-brand-primary-background {
  color: var(--default-text-color);
  background-color: var(--gray-100);
}

.action-text, .action-text:hover, .action-text:focus,
.action-text > a, .action-text > a:hover, .action-text > a:focus,
.hover-parent:hover > .action-parent-hover-text {
  color: var(--active-text-hover-color) !important;
}

.action-hover-text:hover, .action-hover-wrapper:hover .action-hover-text {
  color: var(--active-text-hover-color) !important;
}

.action-hover-border:hover, .action-hover-wrapper:hover .action-hover-border {
  border-color: var(--action-600) !important;
}

.action-background-only, .bg-primary {
  background-color: var(--action-600);
}

.action-border, .action-border-hover:hover {
  border-color: var(--action-600);
}

.cxs-table-row-hover:hover {
  color: var(--white) !important;
  background-color: var(--default-primary-color);
}

.invert .action-color,
.invert .action-text, .invert .action-text:hover, .invert .action-text:focus,
.invert .action-text > a, .invert .action-text > a:hover, .invert .action-text > a:focus,
.invert .hover-parent:hover > .action-parent-hover-text {
  color: var(--white) !important;
}
.invert .action-hover-text:hover, .invert .action-hover-wrapper:hover .action-hover-text {
  color: var(--white) !important;
}
.invert .action-hover-border:hover, .invert .action-hover-wrapper:hover .action-hover-border {
  border-color: var(--white) !important;
}
.invert .action-background-only {
  background-color: var(--white);
}
.invert .action-border, .invert .action-border-hover:hover {
  border-color: var(--white);
}

/* case management */
input[checked=checked] + [uib-dropdown] > label:before {
  color: var(--default-primary-color);
}

.br-brand-font-1 {
  color: var(--action-600);
}

.fake-select .caret,
.option-chicklet .caret,
.dropdown-toggle .caret,
.br-organization-panel .caret,
.slick-sort-indicator {
  color: var(--default-text-color);
}

.hierarchy-item.selected, .searchable-hierarchy-list .selected > .hierarchy-item, .br-hierarchy-node[selected] > a, .br-hierarchy-node[selected] > a > .br-hierarchy-node-name, .hierarchy-item:hover .action-color {
  color: var(--white);
  background-color: var(--action-800);
}

.toggle-switch > div {
  background: var(--action-800);
}
.toggle-switch:not(.disabled):hover > div {
  background: var(--active-text-hover-color);
}
.toggle-switch:active > div {
  background: var(--action-900);
}
.toggle-switch.disabled > div, .toggle-switch.disabled:hover > div, .toggle-switch.disabled:active > div {
  opacity: 0.3;
}
.toggle-switch.disabled .switch-off, .toggle-switch.disabled .switch-off:hover, .toggle-switch.disabled .switch-off:active, .toggle-switch.disabled .switch-off:focus {
  background: var(--gray-600);
}
.invert .toggle-switch > div {
  background: var(--white);
}
.invert .toggle-switch .knob {
  background: var(--action-800);
}
.toggle-switch > .switch-off {
  background: var(--gray-600);
}
.toggle-switch > .switch-off:hover {
  background: var(--gray-400);
}
.toggle-switch > .switch-off:focus {
  background: var(--gray-600);
}
.toggle-switch > .switch-off:active {
  background: var(--gray-900);
}

.header-element:hover [ngbdropdowntoggle] .q-icon {
  color: var(--header-link-color-hover);
}

cb-account-switcher:hover a {
  color: var(--header-link-color-hover);
}
cb-account-switcher:focus-within {
  color: var(--header-link-color-hover);
}

.br-header {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.br-header .header-element, .br-header cb-account-switcher, .br-header cb-spaces {
  color: var(--header-link-color);
  border-color: var(--gray-300);
}
.br-header cb-account-switcher:hover a {
  color: var(--header-link-color-hover);
}
.br-header cb-account-switcher:focus-within {
  color: var(--header-link-color-hover);
}
.br-header #spaces-menu > div:first-child > a:first-of-type:focus {
  color: var(--header-link-color-hover) !important;
  outline-style: solid !important;
  outline-width: 2px !important;
  outline-color: var(--focus-outline-color) !important;
}
.br-header .dropdown.show, .br-header .dropdown.open {
  background-color: var(--header-active-menu-bg);
  color: var(--header-link-color-hover, var(--white));
}
.br-header .header-link:focus, .br-header .dropdown-toggle:focus, .br-header button:focus {
  color: var(--header-link-color-hover);
  outline: solid 2px var(--focus-outline-color);
}
.br-header #sidebar-toggle:focus {
  color: var(--header-link-color-hover);
}
.br-header button {
  line-height: 1.3;
}

#main-sidebar {
  --cxs-sidebar-bg: var(--white);
  background-color: var(--cxs-sidebar-bg);
}
#main-sidebar nav {
  background-color: var(--cxs-sidebar-bg) !important;
}
#main-sidebar label, #main-sidebar .text-white {
  color: var(--cxs-sidebar-color) !important;
}
#main-sidebar #linkTextFilter {
  border-width: 1px !important;
}
#main-sidebar .focus-dashed-white-outline:focus {
  outline: solid 2px var(--focus-outline-color) !important;
}

.header-color-2 {
  background: var(--custom-bg-secondary, var(--white));
  color: var(--default-text-color);
  border-color: var(--black-50-percent);
}

label {
  color: inherit;
}

a:hover {
  color: var(--active-text-hover-color);
}

.accordion .card-header .btn-link:hover {
  color: var(--active-text-hover-color);
}

.cg-busy-default-sign {
  border-color: var(--white-50-percent);
  background-color: var(--gray-200);
  color: var(--default-text-color);
}

a.btn-icon:hover, a.btn-icon:focus, button.btn-icon:hover, button.btn-icon:focus {
  color: var(--default-primary-color);
}

.side-nav-item:hover {
  background: var(--default-primary-color);
}
.side-nav-sub-item > a {
  color: var(--gray-400);
}
.side-nav-item.active > a:hover {
  color: var(--white);
}

a.btn-icon:hover, a.btn-icon:focus, button.btn-icon:hover, button.btn-icon:focus, a.btn-icon-small:hover, a.btn-icon-small:focus,
.br-selected-metric .btn-icon-small.selected {
  color: var(--default-primary-color);
}

/* contextmenu */
#dsh-context-menu .selected > div > ul > li > .hierarchy-item,
#dsh-context-menu .selected > div > ul > li > .hierarchy-item > .br-hierarchy-node-toggle {
  background-color: var(--default-primary-color);
  color: var(--white);
}

.hierarchy-item {
  color: var(--default-text-color);
}

.gridster-preview-holder, .gridster-preview-line {
  border-color: var(--action-600);
}
.gridster-preview-holder::before, .gridster-preview-line::before {
  color: var(--action-600);
}

.gridster-placeholder {
  border-color: var(--gray-1000);
  z-index: 0;
}
.gridster-placeholder::before {
  color: var(--gray-1000);
}

.br-about-image {
  background-color: var(--gray-1000);
}

.mention-link {
  color: var(--gray-900) !important;
  background: var(--white) !important;
}

.nav-pills > li.active a, .nav-pills > li.active a:focus, .nav-pills > li.active a:hover {
  color: var(--white);
  background-color: var(--action-800);
}
.nav-pills > li:not(.read-only) a:not(.disabled):focus, .nav-pills > li:not(.read-only) a:not(.disabled):hover {
  color: var(--white);
  background-color: var(--action-800);
}
.nav-pills > li > a.disabled {
  color: var(--gray-400);
}
.nav-pills > li > a.disabled:hover {
  background-color: var(--gray-1000);
}
.nav-pills > li.selected > a, .nav-pills > li.selected > a:hover, .nav-pills > li.selected > a:focus, .nav-pills > li.selected > label, .nav-pills > li.selected > label:hover, .nav-pills > li.selected > label:focus {
  color: var(--white);
  background-color: var(--action-800);
  fill: var(--gray-200);
  font-weight: normal;
}
.nav-pills > li.selected > label a {
  color: var(--default-text-color);
}
.nav-pills > li.read-only > a, .nav-pills > li.read-only > a:hover, .nav-pills > li.read-only > a:focus {
  background-color: var(--default-text-color);
}

.dropdown-menu, .dropdown-menu li > span, .dropdown-menu li > a, .dropdown-menu li > button, .dropdown-menu li > div > .filter-child, .dropdown-menu .dropdown-item {
  background-color: var(--white);
  color: var(--default-text-color);
}
.dropdown-menu .danger-menu-item {
  color: var(--danger-600) !important;
}

.text-menu-color {
  color: var(--default-text-color);
}

.bg-menu-color {
  background-color: var(--white);
}

.dropdown-menu:not(.disabled):not(.read-only) li > button:hover path, .dropdown-menu:not(.disabled):not(.read-only) li > a:hover path, .dropdown-menu:not(.disabled):not(.read-only) li > a.focus-within path, .dropdown-menu:not(.disabled):not(.read-only) li > span.br-hierarchy-node-toggle:focus path, .dropdown-menu:not(.disabled):not(.read-only) li > a:focus-within path, .dropdown-menu:not(.disabled):not(.read-only) li div:focus > .dropdown-item path, .dropdown-menu:not(.disabled):not(.read-only) .active > button:hover path, .dropdown-menu:not(.disabled):not(.read-only) .active > a:hover path, .dropdown-menu:not(.disabled):not(.read-only) .active > a.focus-within path, .dropdown-menu:not(.disabled):not(.read-only) .active > span.br-hierarchy-node-toggle:focus path, .dropdown-menu:not(.disabled):not(.read-only) .active > a:focus-within path, .dropdown-menu:not(.disabled):not(.read-only) .active div:focus > .dropdown-item path, .dropdown-menu:not(.disabled):not(.read-only) li > div > button:hover path, .dropdown-menu:not(.disabled):not(.read-only) li > div > a:hover path, .dropdown-menu:not(.disabled):not(.read-only) li > div > a.focus-within path, .dropdown-menu:not(.disabled):not(.read-only) li > div > span.br-hierarchy-node-toggle:focus path, .dropdown-menu:not(.disabled):not(.read-only) li > div > a:focus-within path, .dropdown-menu:not(.disabled):not(.read-only) li > div div:focus > .dropdown-item path {
  stroke: var(--gray-900);
}
.dropdown-menu:not(.disabled):not(.read-only) .danger-menu-item:hover, .dropdown-menu:not(.disabled):not(.read-only) .danger-menu-item:focus {
  color: var(--white) !important;
  background-color: var(--danger-600) !important;
}
.dropdown-menu.disabled li > a:hover, .dropdown-menu.disabled li > a:focus, .dropdown-menu.disabled .active > a:hover, .dropdown-menu.disabled .active > a:focus, .dropdown-menu.disabled li > div > a:hover, .dropdown-menu.disabled li > div > a:focus, .dropdown-menu.read-only li > a:hover, .dropdown-menu.read-only li > a:focus, .dropdown-menu.read-only .active > a:hover, .dropdown-menu.read-only .active > a:focus, .dropdown-menu.read-only li > div > a:hover, .dropdown-menu.read-only li > div > a:focus {
  color: inherit;
  background: inherit;
}
.dropdown-menu.disabled .active > a, .dropdown-menu.read-only .active > a {
  color: inherit;
  background: inherit;
}

.dropdown-input .dropdown-toggle {
  background-color: var(--white);
  color: var(--default-text-color);
  border: 1px solid var(--gray-500);
}

.dropdown-header {
  background-color: var(--gray-200);
  color: var(--default-text-color);
}

textarea, input, select, .br-color-dropdown button, .dropdown.fake-select button:not(.ignore-dropdown-style),
.form-control, .input, .fake-select > button:not(.ignore-dropdown-style), .dropdown-input.dropdown, .discussion-submission {
  background-color: var(--white);
  color: var(--default-text-color);
}
input:not([type=checkbox]):not([type=radio]) {
  background-color: var(--white);
}

.dropdown-menu .no-hover, .dropdown-menu .no-hover > a {
  color: var(--gray-900) !important;
  background-color: var(--white) !important;
}

.dropdown-input.dropdown a[uib-dropdown-toggle] {
  background-color: var(--gray-1000);
  padding: 8px;
}

.table tr .toggle-switch-animate.switch-on,
.cases-table tr .toggle-switch-animate.switch-on,
.br-grid .slick-row .toggle-switch-animate.switch-on,
.br-log-grid .slick-row .toggle-switch-animate.switch-on {
  background-color: var(--action-600);
}
.table tr .toggle-switch-animate.switch-on .knob,
.cases-table tr .toggle-switch-animate.switch-on .knob,
.br-grid .slick-row .toggle-switch-animate.switch-on .knob,
.br-log-grid .slick-row .toggle-switch-animate.switch-on .knob {
  background-color: var(--gray-200);
}
.table tr .toggle-switch-animate.switch-off,
.cases-table tr .toggle-switch-animate.switch-off,
.br-grid .slick-row .toggle-switch-animate.switch-off,
.br-log-grid .slick-row .toggle-switch-animate.switch-off {
  background-color: var(--gray-200);
}
.table tr .toggle-switch-animate.switch-off .knob,
.cases-table tr .toggle-switch-animate.switch-off .knob,
.br-grid .slick-row .toggle-switch-animate.switch-off .knob,
.br-log-grid .slick-row .toggle-switch-animate.switch-off .knob {
  background-color: var(--gray-100);
}

.br-widget-content .slick-header-column:focus {
  background-color: var(--action-200);
  color: var(--default-text-color);
}

.br-show-total .slick-row:hover, .br-widget-content .slick-row:hover,
.cb-preview-table tr:hover,
.br-widget-content .slick-header-column:hover,
.preview-tabe-view .slick-header-column:hover, .br-show-total .hover.slick-row, .br-widget-content .hover.slick-row,
.cb-preview-table tr.hover,
.br-widget-content .hover.slick-header-column,
.preview-tabe-view .hover.slick-header-column {
  background-color: var(--action-200);
  color: var(--default-text-color);
}

.br-widget-content .slick-row,
.cb-preview-table tr,
.br-widget-content .slick-header-column,
.preview-tabe-view .slick-header-column {
  background-color: var(--white);
}
.point-selection .br-widget-content .slick-row.active,
.point-selection .cb-preview-table tr.active,
.point-selection .br-widget-content .slick-header-column.active,
.point-selection .preview-tabe-view .slick-header-column.active {
  color: var(--white);
  background-color: var(--default-primary-color);
}
.br-widget-content .slick-row.alternate, .br-widget-content .slick-row.odd:not(.ignore-odd):not(.slick-parent-row),
.cb-preview-table tr.alternate,
.cb-preview-table tr.odd:not(.ignore-odd):not(.slick-parent-row),
.br-widget-content .slick-header-column.alternate,
.br-widget-content .slick-header-column.odd:not(.ignore-odd):not(.slick-parent-row),
.preview-tabe-view .slick-header-column.alternate,
.preview-tabe-view .slick-header-column.odd:not(.ignore-odd):not(.slick-parent-row) {
  background-color: var(--gray-100);
  color: var(--default-text-color);
}
.br-widget-content .slick-row.alternate:hover, .br-widget-content .slick-row.alternate.hover, .br-widget-content .slick-row.odd:not(.ignore-odd):not(.slick-parent-row):hover, .br-widget-content .slick-row.odd:not(.ignore-odd):not(.slick-parent-row).hover,
.cb-preview-table tr.alternate:hover,
.cb-preview-table tr.alternate.hover,
.cb-preview-table tr.odd:not(.ignore-odd):not(.slick-parent-row):hover,
.cb-preview-table tr.odd:not(.ignore-odd):not(.slick-parent-row).hover,
.br-widget-content .slick-header-column.alternate:hover,
.br-widget-content .slick-header-column.alternate.hover,
.br-widget-content .slick-header-column.odd:not(.ignore-odd):not(.slick-parent-row):hover,
.br-widget-content .slick-header-column.odd:not(.ignore-odd):not(.slick-parent-row).hover,
.preview-tabe-view .slick-header-column.alternate:hover,
.preview-tabe-view .slick-header-column.alternate.hover,
.preview-tabe-view .slick-header-column.odd:not(.ignore-odd):not(.slick-parent-row):hover,
.preview-tabe-view .slick-header-column.odd:not(.ignore-odd):not(.slick-parent-row).hover {
  background-color: var(--action-200);
}

.widget-tile {
  color: var(--gray-1000);
  background-color: var(--white);
}
.widget-tile:hover {
  color: var(--action-800);
  background-color: var(--action-100);
}
.widget-tile.ui-draggable-dragging {
  color: var(--action-800);
  background-color: var(--action-100);
}
.widget-tile:active {
  color: var(--action-900);
}

.toolbar-drop-area {
  background-color: var(--action-300);
}
.toolbar-drop-area.drag-enter {
  border-color: var(--default-primary-color);
}

.selected .slick-cell, .slick-cell.selected {
  background-color: var(--default-primary-color);
  border-color: var(--white);
  color: var(--white);
}

.selected:hover > .action-parent-hover-text {
  color: var(--white) !important;
}

.disabled .action-hover-text:hover, .disabled .action-hover-wrapper:hover .action-hover-text {
  color: inherit !important;
}

.dashboard-tab .btn .tab-action {
  color: getContrastColorLighten(var(--white));
}

.brand-secondary-bg-fade-start .fade-container:before {
  background: linear-gradient(270deg, transparent, var(--white));
}

.brand-secondary-bg-fade-end .fade-container:after {
  background: linear-gradient(90deg, transparent, var(--white));
}

.nf-list-group li a {
  color: var(--default-primary-color);
}
.nf-list-group li a:focus, .nf-list-group li a:hover {
  color: var(--active-text-hover-color);
}

selector-widget-button > div:focus {
  border-color: var(--default-primary-color);
  box-shadow: 0 0 5px var(--action-600);
}

.cx-add-widget-toolbar {
  background-color: var(--white);
}

empty-content {
  background-color: var(--white);
}
empty-content .q-icon {
  color: var(--gray-400);
}

.pin-slick-header item-grid .slick-header {
  background-color: var(--white);
}

.br-widget-content .slick-header-column {
  background: var(--white);
}
.br-widget-content .slick-header-column:hover, .br-widget-content .slick-header-column-active {
  background: var(--gray-200);
}
.br-widget-content .slick-headerrow {
  background: var(--gray-200);
}
.br-widget-content .slick-headerrow-column {
  background: var(--gray-200);
}
.br-widget-content .slick-row {
  background: var(--white);
}
.br-widget-content .slick-row.ui-state-active {
  background: var(--gray-200);
}
.br-widget-content .slick-cell.selected {
  background-color: var(--gray-200);
}
.br-widget-content .slick-sortable-placeholder {
  background: var(--gray-400) !important;
}

.br-show-total .slick-row.slick-leaf-row {
  background-color: var(--white);
  color: var(--default-text-color);
}
.br-show-total .slick-row.slick-leaf-row:hover {
  background-color: var(--default-text-color);
  color: var(--action-200);
}
.br-show-total .slick-row.slick-row-total {
  background-color: var(--default-text-color);
  color: var(--white);
}
.br-show-total .slick-row.slick-row-total:hover {
  background-color: var(--action-200);
  color: var(--default-text-color);
}
.br-show-total .slick-row.slick-row-level-0, .br-show-total .slick-row.alternate.slick-row-level-0, .br-show-total .slick-row.odd.slick-row-level-0 {
  background-color: var(--gray-400);
  color: var(--default-text-color);
}
.br-show-total .slick-row.slick-row-level-1, .br-show-total .slick-row.alternate.slick-row-level-1, .br-show-total .slick-row.odd.slick-row-level-1 {
  background-color: var(--gray-400);
  color: var(--default-text-color);
}
.br-show-total .slick-row.slick-row-level-2, .br-show-total .slick-row.alternate.slick-row-level-2, .br-show-total .slick-row.odd.slick-row-level-2 {
  background-color: var(--gray-600);
  color: var(--white);
}
.br-show-total .slick-row.slick-row-level-3, .br-show-total .slick-row.alternate.slick-row-level-3, .br-show-total .slick-row.odd.slick-row-level-3 {
  background-color: var(--gray-600);
  color: var(--white);
}
.br-show-total .slick-row:hover, .br-show-total .slick-row.odd:hover, .br-show-total .slick-row.even:hover, .br-show-total .slick-row.hover {
  background-color: var(--action-200);
  color: var(--default-text-color);
}

.metric-period-label, .metric-change-labels, .single-metric-chart, .single-metric-chart .metric-label {
  fill: var(--gray-900);
}

.metric-change-line {
  stroke: var(--gray-900);
}

.highcharts-legend-item-hidden .highcharts-point {
  fill: var(--gray-400) !important;
  stroke: var(--gray-900) !important;
  color: var(--gray-900) !important;
}
.highcharts-legend-item-hidden text {
  fill: var(--gray-900) !important;
}

.cloud-selection-table tr {
  background-color: var(--white);
  color: var(--default-text-color);
}
.cloud-selection-table tbody tr.odd {
  background-color: var(--gray-200);
  color: var(--default-text-color);
}
.cloud-selection-table tbody tr:hover {
  background-color: var(--action-200);
}

.br-document-preview-attribute {
  background-color: var(--white);
}

.bubble {
  border: 1px solid;
  border-color: var(--gray-400);
}

.arrow-down-border {
  border-top-color: var(--gray-600);
}

.feedback-preview-bubble-item .arrow-down {
  border-color: var(--white) transparent transparent transparent;
}

.feedback-preview-source-icon {
  height: 40px;
  width: 40px;
}

.arrow-down, .arrow-down-border {
  border-color: var(--gray-400) transparent transparent transparent;
}

.br-interaction-explorer-grid .label-bubble {
  background-color: transparent;
}

.br-widget-box.SELECTOR_BUTTON .br-widget-content {
  border-bottom-left-radius: var(--widget-border-radius);
  border-bottom-right-radius: var(--widget-border-radius);
}

document-previewer, .cb-preview-table-container, tuning-suggestion-summary {
  background-color: var(--gray-300);
}
document-previewer .dex-list-group, .cb-preview-table-container .dex-list-group, tuning-suggestion-summary .dex-list-group {
  background-color: var(--white);
}
document-previewer .dex-list-group li.selected, .cb-preview-table-container .dex-list-group li.selected, tuning-suggestion-summary .dex-list-group li.selected {
  background-color: var(--white);
}
document-previewer .label-bubble, .cb-preview-table-container .label-bubble, tuning-suggestion-summary .label-bubble {
  background-color: transparent;
  color: var(--default-text-color);
}
document-previewer .label-bubble.selected, .cb-preview-table-container .label-bubble.selected, tuning-suggestion-summary .label-bubble.selected {
  background: var(--gray-200);
}
document-previewer .label-bubble.disabled, .cb-preview-table-container .label-bubble.disabled, tuning-suggestion-summary .label-bubble.disabled {
  opacity: 0.7;
}
document-previewer tabbed-context-pane .label-bubble.selected, .cb-preview-table-container tabbed-context-pane .label-bubble.selected, tuning-suggestion-summary tabbed-context-pane .label-bubble.selected {
  color: var(--blue-100);
  background-color: var(--blue-800);
}
document-previewer .dex-model-attribute .label-bubble, .cb-preview-table-container .dex-model-attribute .label-bubble, tuning-suggestion-summary .dex-model-attribute .label-bubble {
  background: var(--gray-400);
}
document-previewer .doc-explorer-panel .panel-heading, .cb-preview-table-container .doc-explorer-panel .panel-heading, tuning-suggestion-summary .doc-explorer-panel .panel-heading {
  color: var(--gray-1000);
  background: var(--gray-200);
}
document-previewer .dex-left-pane, .cb-preview-table-container .dex-left-pane, tuning-suggestion-summary .dex-left-pane {
  background-color: var(--white);
}
.panel-body {
  color: var(--default-text-color);
}

.br-versions-panel .nav-pills > li:not(.selected) > label {
  color: var(--default-text-color);
}

.an-document-explorer .dx-filters .btn-selected {
  color: var(--white) !important;
}

.sentence-context {
  color: var(--default-text-color);
}

.explorer-tools {
  background-color: var(--white);
}
.explorer-tools .slim-dropdown li:hover {
  background-color: var(--gray-400);
}

a[disabled] {
  color: var(--gray-900) !important;
}

.list-group-item {
  background-color: var(--gray-200);
}

.twitter-container .twitter-metadata span, .twitter-container .twitter-timestamp, .twitter-container .twitter-metadata, .twitter-container .twitter-creator {
  color: var(--default-text-color);
}

.gridster-widget.selected {
  outline: 2px solid var(--gray-1000) !important;
  border-radius: var(--widget-border-radius);
}

.br-widget-placeholder {
  background-color: var(--white);
}

.cases-table.table tr:hover > td, .cases-table.table tr:hover > th, .cases-table.table tr.even:hover > td, .cases-table.table tr.even:hover > th, .cases-table.table tr.odd:hover > td, .cases-table.table tr.odd:hover > th {
  background-color: var(--action-200);
  color: var(--default-text-color);
}

.br-grid .slick-header-columns, .br-user-row .slick-header-columns {
  color: var(--gray-1000);
}

.rounded-grouping-label, .labeled-rule label {
  background-color: var(--white);
}

.icon-highlight:not(.feedback-preview-source-icon) {
  background: var(--white);
}
.icon-highlight:not(.feedback-preview-source-icon).placeholder {
  background: transparent;
}

.positive-change-color {
  fill: var(--pos-sentiment-600);
  color: var(--pos-sentiment-600);
}

.negative-change-color {
  fill: var(--neg-sentiment-600);
  color: var(--neg-sentiment-600);
}

.neutral-change-color {
  fill: var(--gray-600);
}

.positive-effort-change-color {
  fill: var(--effort-easy-600);
  color: var(--effort-easy-600);
}

.negative-effort-change-color {
  fill: var(--effort-hard-600);
  color: var(--effort-hard-600);
}

.multiselect.paging .new-value-dot {
  background-color: var(--default-primary-color);
}

.panel .panel-body.main-panel {
  background-color: var(--white);
}

spine-header {
  background-color: var(--gray-200);
}

.spine-update .silence {
  color: var(--default-text-color);
}

.spine-wrapper {
  background-color: var(--white);
}

.conversation-segment.is-highlighted .sentence-container, .conversation-segment.is-adjacent .sentence-container {
  border-color: var(--blue-700);
}

.tooltip-content.channel-bot, .conversation-segment.bot-transcript .sentence-text-container {
  color: var(--aqua-900);
}

.spine-update .mejs__time {
  color: var(--default-text-color);
}
.spine-update .mejs__time-handle {
  background-color: var(--action-600);
}
.spine-update .mejs__horizontal-volume-total {
  background-color: var(--black-50-percent);
}
.spine-update .mejs__horizontal-volume-current {
  background-color: var(--white-50-percent);
}

cb-text-editor #text-editor .text-wrapper {
  background-color: var(--white);
  color: var(--gray-900);
}
cb-text-editor #text-editor .text-wrapper pre {
  background-color: var(--gray-200);
  color: var(--gray-900);
}

.cb-spine-row.selected, .cb-spine-row.selected.topicHighlight {
  stroke: var(--blue-400);
}

.cb-spine-row.topicHighlight {
  stroke: var(--blue-700);
}

.explorer-tools.audit-mode, .br-widget-header.audit-mode {
  background-color: var(--default-primary-color);
  color: var(--white);
}
.explorer-tools.audit-mode button, .br-widget-header.audit-mode button {
  color: inherit;
}

.interaction-audit-toggle, .intelligent-scoring-toggle {
  color: var(--default-text-color);
}
.interaction-audit-toggle:hover, .intelligent-scoring-toggle:hover {
  color: var(--default-primary-color);
}
.interaction-audit-toggle:disabled, .interaction-audit-toggle:disabled:hover, .intelligent-scoring-toggle:disabled, .intelligent-scoring-toggle:disabled:hover {
  color: var(--default-text-color);
}

.audit-mode .interaction-audit-toggle, .audit-mode .intelligent-scoring-toggle, #dashboardContainer .br-widget-header.audit-mode a {
  background-color: var(--default-primary-color);
  color: var(--white) !important;
}
.audit-mode .interaction-audit-toggle:hover, .audit-mode .intelligent-scoring-toggle:hover, #dashboardContainer .br-widget-header.audit-mode a:hover {
  color: var(--gray-900) !important;
}

.audit-mode .br-sentence-text:hover span, .audit-mode .hover .br-sentence-text span,
.audit-mode .sentence-preview-hover, .audit-mode .voice-sentence-hover .br-sentence-text span {
  background-color: var(--action-300);
}

.interaction-audit-title {
  background-color: var(--default-primary-color);
  color: var(--white);
}

jump-link :hover {
  color: var(--gray-1000);
  background-color: var(--action-200);
}

.audit-mode speech-bubble article p {
  cursor: pointer;
}

.formula-input {
  background-color: var(--gray-200);
  color: var(--default-text-color);
}
.formula-input .math-function {
  color: var(--warning-600);
}
.formula-input .math-attribute {
  color: var(--gray-600);
}
.formula-input .math-aggregation {
  color: var(--emotional-intensity-700);
}
.formula-input .math-predefined-metric {
  color: var(--action-600);
}
.formula-input .math-custom-metric {
  color: var(--emotional-intensity-1000);
}
.formula-input .math-scorecard-metric {
  color: var(--action-600);
}
.formula-input .math-hierarchy-metric {
  color: var(--success-600);
}

.dropdown-menu:not(.disabled):not(.read-only) a.math-function {
  color: var(--warning-600);
}
.dropdown-menu:not(.disabled):not(.read-only) a.math-attribute {
  color: var(--gray-600);
}
.dropdown-menu:not(.disabled):not(.read-only) a.math-aggregation {
  color: var(--emotional-intensity-700);
}
.dropdown-menu:not(.disabled):not(.read-only) a.math-predefined-metric {
  color: var(--action-600);
}
.dropdown-menu:not(.disabled):not(.read-only) a.math-custom-metric {
  color: var(--emotional-intensity-1000);
}
.dropdown-menu:not(.disabled):not(.read-only) a.math-scorecard-metric {
  color: var(--action-600);
}
.dropdown-menu:not(.disabled):not(.read-only) a.math-hierarchy-metric {
  color: var(--success-600);
}

.interaction-filter .filter-builder-header {
  background: var(--gray-200);
}
.interaction-filter .saved-filters {
  border-left: 1px solid var(--gray-200);
}

#quick-search-panel {
  background-color: var(--white);
}

.math-object-tooltip {
  color: var(--default-text-color);
  background-color: var(--white);
}

.math-object-tooltip:before {
  border-bottom-color: var(--white);
}

.bg-white {
  background-color: var(--white);
}

.bg-gray-200 {
  background-color: var(--gray-200);
}

.bg-gray-400 {
  background-color: var(--gray-400);
}

.border-gray-200 {
  border-color: var(--gray-200);
}

.border-gray-600 {
  border-color: var(--gray-600);
}

.border-gray-900 {
  border-color: var(--gray-900);
}

.rows-delimiter {
  border-bottom: 1px solid var(--bg-color-client);
}

.rows-delimiter-top {
  border-top: 1px solid var(--bg-color-client);
}

input[type=checkbox], input[type=radio] {
  accent-color: var(--action-800);
}

.reference-line-builder-dropdown plot-line-preview path {
  stroke: var(--gray-1000) !important;
}
.reference-line-builder-dropdown a:hover {
  background-color: var(--action-400) !important;
}
.reference-line-builder-dropdown a:hover plot-line-preview path {
  stroke: var(--gray-1000) !important;
}
.reference-line-builder-dropdown .selected a {
  background-color: var(--action-900) !important;
}
.reference-line-builder-dropdown .selected a plot-line-preview path {
  stroke: var(--gray-200) !important;
}

.q-tabs > .active::before,
.q-tabs > li:hover::before {
  background: var(--action-1000) !important;
}

.bg-dashboard,
.bg-dashboard\:hover:hover {
  background: var(--custom-bg-primary, var(--gray-100)) !important;
}

.bg-main,
.bg-main\:hover:hover {
  background: var(--custom-bg-primary, var(--gray-100)) !important;
}

real-data-preview.no-background #real-data-preview, real-data-preview.no-background .chart-demo, real-data-preview.no-background .br-widget-view, real-data-preview.no-background .cg-busy-backdrop, report-view-wrapper.no-background #real-data-preview, report-view-wrapper.no-background .chart-demo, report-view-wrapper.no-background .br-widget-view, report-view-wrapper.no-background .cg-busy-backdrop {
  background: var(--dashboard-bg) !important;
}

.bg-secondary,
.bg-secondary\:hover:hover {
  background: var(--custom-bg-secondary, var(--white)) !important;
}

:root {
  --cxs-brand-header-bg: var(--custom-bg-header, var(--white));
}