:root {
	--logo-url: url('../../img/QualtricsXM-Logo.png');

	--white: var(--white-100);
	--header-active-menu-bg: var(--custom-bg-header, var(--white-100));

	--header-link-color: var(--default-text-color);
	--header-link-color-hover: var(--default-primary-color, var(--gray-700));

	--modal-header-text-color: var(--gray-1000);
	--modal-header-background: var(--white);
	--modal-divider-color: var(--gray-500);

	--focus-outline-color: var(--action-1000);
	--focus-background-color: var(--action-700);

	--primary-bg: var(--custom-bg-primary, var(--gray-100));
	--dashboard-bg: var(--primary-bg);


	--button-hover-background: var(--action-800);
	--button-hover-border-color: var(--action-800);
	--button-hover-text-color: var(--white);
}
