/*
	██ ███    ███ ██████   ██████  ██████  ████████  █████  ███    ██ ████████     ██
	██ ████  ████ ██   ██ ██    ██ ██   ██    ██    ██   ██ ████   ██    ██        ██
	██ ██ ████ ██ ██████  ██    ██ ██████     ██    ███████ ██ ██  ██    ██        ██
	██ ██  ██  ██ ██      ██    ██ ██   ██    ██    ██   ██ ██  ██ ██    ██
	██ ██      ██ ██       ██████  ██   ██    ██    ██   ██ ██   ████    ██        ██

	This file should include nothing but color rules!!!

	1. ".dark-mode-class-placeholder" is replaced on backend for scope specific class appending ' &', where appropriate
	2. "html {" is replaced with scope specific element, so everything should be inside html rule
*/

@import "brand-base.scss";




%brand-primary-border { border-color: var(--default-primary-color); }

@mixin action-color-background {
	color: var(--white);
	background-color: var(--action-800);
}

@mixin primary-background {
	color: var(--default-text-color);
	background-color: var(--gray-100);
}
@mixin secondary-background {
	color: var(--default-text-color);
	background-color: var(--gray-200); // TODO or 100 here and 200 above?
}
@mixin white-background {
	color: var(--default-text-color);
	background-color: var(--white);
}



$button-focus-shadow-color: var(--action-200);
$button-active-background-color: var(--action-900);

%branded-row-hover {
	&:hover, &.hover {
		color: var(--default-text-color);
		background-color: var(--action-200);
	}
}

%brand-lighten-background {
	fill: var(--white);
	background-color: var(--default-primary-color);
	color: var(--white);
}

%form-focus-shadow { outline: solid 2px var(--focus-outline-color); outline-offset: -2px; }
%branded-widget-table-row-hover {
	&:hover, &.hover {
		background-color: var(--action-200);
		color: var(--default-text-color);
	}
}

@mixin default-link-style {
	color: var(--default-primary-color);
	@include link-states;
}

@mixin link-states {
	&:focus, &:hover { color: var(--active-text-hover-color); }
}

cb-sidebar-nav a:focus-visible {
	outline: solid 2px var(--gray-100);
	outline-offset: -2px;
}

.cxs-brand-primary { color: var(--default-primary-color); }
.cxs-brand-primary-100 { color: var(--action-200);}
.bg-cxs-brand-primary-100 { background: var(--action-200);}

.cxs-brand-primary-200 { color: var(--action-300); }
.bg-cxs-brand-primary-200 { background: var(--action-300); }

.cxs-brand-primary-300 { color: var(--action-400); }
.bg-cxs-brand-primary-300 { background: var(--action-400); }

.cxs-brand-primary-500 { color: var(--default-primary-color); }
.bg-cxs-brand-primary-500 { background: var(--default-primary-color); }

.cxs-brand-primary-700 { color: var(--action-800); }
.bg-cxs-brand-primary-700 { background: var(--action-800); }

.cxs-brand-primary-900 { color: var(--action-1000); }
.bg-cxs-brand-primary-900 { background: var(--action-1000); }


// inverted versions for rare use
// TODO revisit
.invert {
	.cxs-brand-primary { color: var(--default-primary-color); }
	.cxs-brand-primary-100 { color: var(--action-1000); }
	.bg-cxs-brand-primary-100 { background: var(--action-1000); }

	.cxs-brand-primary-200 { color: var(--action-800); }
	.bg-cxs-brand-primary-200 { background: var(--action-800); }

	.cxs-brand-primary-300 { color: var(--default-primary-color); }
	.bg-cxs-brand-primary-300 { background: var(--default-primary-color); }

	.cxs-brand-primary-500 { color: var(--white); }
	.bg-cxs-brand-primary-500 { background: var(--white); }

	.cxs-brand-primary-700 { color: var(--action-300); }
	.bg-cxs-brand-primary-700 { background: var(--action-300); }

	.cxs-brand-primary-900 { color: var(--action-200);}
	.bg-cxs-brand-primary-900 { background: var(--action-200);}
}

html { color: var(--gray-900); }
a {
	color: var(--default-text-color);
	&:hover {
		color: var(--active-text-hover-color);

	}
	&.inline {
		text-decoration: underline;
		&:hover {
			color: var(--action-500);
		}
	}
}


pre {
	background-color: var(--gray-200);
	color: var(--default-text-color);
}

fieldset[disabled] .btn-primary
{
	&.active, &:active, &:focus, &:hover {
		background-color: var(--white);
	}
}

#custom-expression-container {
	background-color: var(--gray-100);
}
.expression-chicklet.action-border {
	background-color: var(--gray-100);
}


.gridster-loaded .br-widget-box:not(.CONTENT):not(.bg-none) {
	background-color: var(--gray-100);
	outline-offset: -1px;
}

label {
	color: var(--default-text-color);
}
a:hover>label {
	color: var(--white);
}
.no-results {
	color: var(--default-text-color);
}
.help-block {
	color: var(--default-text-color);
}

.loader {
	border-top: .5rem solid var(--gray-200);
	border-right: .5rem solid var(--gray-200);
	border-bottom: .5rem solid var(--gray-200);
	border-left: .5rem solid var(--action-600);
}

tags-input .tags {
	background-color: var(--white);
}

.cg-busy-backdrop {
	opacity: .7 !important;
}

.CB.MAP .cg-busy-backdrop {
	opacity: 1 !important;
	background-color: var(--white);
}

.br-widget-header, .br-widget-caption {
	background-color: var(--white);
}

.br-widget-footer {
	background-color: var(--white);
	color: var(--default-text-color);
	border-bottom-left-radius: var(--widget-border-radius);
	border-bottom-right-radius: var(--widget-border-radius);;
}
.demo-heatmap-footer {
	background-color: var(--white);
}

.br-widget-total, .br-widget-total-demo {
	color: var(--default-text-color);
}

.bg-none {
	.br-widget-view, .br-widget-footer { background: none !important; }
	.br-widget-content {
		.slick-header-column, .slick-row:not(:hover) {
			background: none !important;
		}
	}
}
.widget-image-export .bg-none {
	background-color: var(--gray-200) !important;
}

.gridster-loaded .br-widget-box {
	&:not(.CONTENT):not(.bg-none) { background: var(--white); }
}

.br-widget-view {
	background-color: var(--white);
}

.hover-bg-cxs-brand:hover { background-color: var(--default-primary-color); }

.gauge-chart {
	metric-label {
		text-shadow: -1px 0 var(--gray-100), 0 1px var(--gray-100), 1px 0 var(--gray-100), 0 -1px var(--gray-100);
	}
}

.hover-bg-cxs-brand-100 { @extend %branded-row-hover; }
.hover-bg-cxs-brand-200:hover { background-color: var(--action-300); }
.hover-bg-cxs-brand-300:hover { background-color: var(--action-400); }
.hover-bg-cxs-brand-400:hover { background-color: var(--default-primary-color); }
.hover-bg-cxs-brand-500:hover { background-color: var(--action-800); }
.hover-bg-cxs-brand-600:hover { background-color: var(--action-1000); }


@keyframes fadeOut{
	from {
		background-color: var(--action-200);
	}
	to {
		background-color: transparent;
	}
}

.brand-fade-out {
	animation-name: fadeOut;
	animation-duration: 2s;
	animation-iteration-count: 1;
	animation-timing-function: ease-in-out;
}

.soft-selection {
	outline: solid 2px $button-active-background-color;
	@extend %brand-lighten-background;
}

a, .branded-link, .branded-link-wrapper a {
	@include default-link-style;

	&.deselected { color: var(--action-500); }
}

.q-tabs .nav-link {
	@include link-states;
}

/*logo*/
.br-logo {
	box-sizing: border-box;
	background: var(--logo-url) no-repeat;
	background-size: contain;
	background-position: center;
}

.branded-link, .branded-link-wrapper a {
	color: var(--default-primary-color) !important;
	&:focus, &:hover { color: var(--action-400) !important; }
}

/*keyboard navigation*/
.kb-focusable, a, [uib-datepicker] [tabindex="0"], button {
	&:focus-visible {
		z-index: 1;
	}
	&:focus {
		@extend %form-focus-shadow;
	}
}

.kb-focusable-inset, .slick-cell, .slick-header-column, dashboard-filters-edit a,
.filter-date-picker .filter-time-text, .filter-date-picker .filter-time-selector {
	&:focus {
		@extend %form-focus-shadow;
	}
}
	.iframe-focus-bumber:focus + iframe {
		@extend %form-focus-shadow;
	}

.sub-menu-item:focus {
	@extend %brand-lighten-background;

	#actionOption a {
		@extend %brand-lighten-background;
		background-clip: content-box;
	}
}

.outset-focus-indicator:focus-visible {
	@extend %form-focus-shadow;
	outline-offset: 0 !important; // force outside focus
}

.kb-focusable-border:focus-visible {
	border: solid 2px $button-active-background-color;
	&#main-dashboard-controller {
		margin-bottom: 2px;
	}
	.br-global-view {
		margin: -2px;
	}
	.secondary-header {
		margin: -2px;
		margin-bottom: 0;

		+ .br-global-view {
			margin-top: 0;
		}
	}
}

.document-audio-player, .document-audio-player [tabindex="0"] {
	&:focus-visible {
		@extend %form-focus-shadow;
	}
}

.cb-spine-row:focus-visible {
	stroke: $button-focus-shadow-color;
}

a.labeled-checkbox:focus-within {
	outline: solid 2px var(--focus-outline-color) !important;
}

.cloud-word:focus-visible, .cloud-word.highcharts-point-hover {
	@extend %form-focus-shadow;
}

#bypass-block a, #bypass-modal-block a {
	background: none repeat scroll 0 0 var(--white);
	color: var(--gray-900);
	&:focus {
	outline: solid 2px var(--focus-outline-color);
	}
}

/*button*/

%primary-button {
	background-color: var(--action-700);
	color: var(--white);
	border-color: var(--action-700);
}

%button-hover {
	background-color: var(--button-hover-background);
	border-color: var(--button-hover-border-color);
	color: var(--button-hover-text-color);
	fill: var(--button-hover-text-color);
}

// no difference between primary and secondary button hover right now
%primary-button-hover, %secondary-button-hover {
	@extend %button-hover;
}

.btn:not(.no-hover) {
	&:hover {
		@extend %primary-button-hover;
	}

	&:focus {
		outline: solid 2px var(--focus-outline-color);
		outline-offset: var(--default-outline-offset);

		// pos: relative and z-index help prevent focus outline being overlapped in some circumstances
		position: relative;
		z-index: 1;

		&:hover:not(.btn-icon):not(.btn-selected):not(.btn-secondary) { // this behavior, focus + hover = active only makes sense for primary button, not secondary buttons
			background-color: $button-active-background-color;
			border-color: $button-active-background-color;
			color: var(--white);
		}
	}
}

.btn-group > .btn:not(.btn-selected):hover { @extend %primary-button-hover; } // for push buttons
.btn-group {
	.btn:not(.no-hover) {
		&.btn-selected {
			&:focus {
				background-color: var(--action-800);
				color: var(--white);
			}
		}
	}
}

.sign-in-page-container .btn-embedded-primary {
	@extend %primary-button;

	&:hover {
		@extend %button-hover;
	}
}

.btn-primary, .uib-datepicker-popup .uib-close, .btn-info.active {
	&, &:focus, &[disabled], &[disabled]:focus, &[disabled]:hover, &[disabled]:active {
		@extend %primary-button;
	}

	&.btn-link, &.btn-text {
		background-color: transparent;
		border-color: transparent;
		color: var(--default-primary-color);

		&:hover {
			background-color: var(--button-hover-background);
			border-color: var(--button-hover-border-color);

			&:focus { color: var(--button-hover-text-color); }
		}
	}
}

a.nav-link {
	color: inherit;
}

ngb-datepicker-navigation .btn {
	&.btn-link {
		background-color:transparent;
		border-color:transparent;

		&:hover, &:focus {
			background-color: var(--button-hover-background);
			border-color: var(--button-hover-border-color);

		}
	}
	background-color: var(--white);
	color: var(--action-700);
}

.custom-day.range, .custom-day:hover {
	background-color: var(--default-primary-color);
	color: var(--white);
}

.custom-day.faded {
	background-color: var(--action-800);
}


checkbox-button .btn-secondary {
	border-color: transparent;
	color: var(--default-text-color);
	&.btn:not(.no-hover):not(.btn-icon):not(.btn-selected) {
		&:hover {
			background-color: var(--action-100);
			color: var(--action-800);
		}
		&:focus {
			outline-offset: -2px;
			&:hover { // we have to add this now because btn focus+hover is weird (above)
				background-color: var(--action-100);
				color: var(--action-800);
			}
		}
	}
}

%selected-button-style {
	background-color: var(--action-900);
	color: var(--white);
	fill: var(--gray-200);
	border:1px solid var(--action-900)
}

// .btn-group .btn {
// 	&.btn-selected, &.active {
// 		@extend %selected-button-style;
// 	}
// }

// link button has no fill, so selected state requires special handling
a.btn-link { color: var(--default-primary-color); }
.btn-link.btn-selected {
	&.btn-secondary {
		background-color: var(--action-800);
		color: var(--white);
	}
}

[uib-datepicker] .btn, .date-point-day.btn {
	&.active, &.focused, &:hover {
		@extend %primary-button-hover;
	}
	&.selected {
		@extend %selected-button-style;
	}
}

.uib-datepicker-current, .uib-datepicker-popup .uib-clear, .btn-info {
	color:var(--gray-900);
	border:1px solid var(--gray-900);
	background:var(--white);
}


.pagination {

	>li:not(.active):not(.disabled), .page-item:not(.disabled)>.page-link {

		>a, >span {
			&:hover {
				color: var(--action-800);
				background-color: var(--action-100);
				border: 1px solid var(--action-100);
			}
			&:focus {
				color: var(--action-800);
				outline-color: var(--action-800);
			}
		}
	}

	>.active>a, >.active>span {
		&, &:hover, &:focus {
			@include action-color-background;
			color: var(--white);
			border-color: var(--action-800);
			outline-color: var(--action-800);
		}
	}
}

/*dialogue, popup, modal*/
.header-link {
	color: inherit;
	padding: 8px;
}


.modal-header {
	color: var(--modal-header-text-color);
	background-color: var(--modal-header-background);
	border-bottom: 1px solid var(--modal-divider-color);
	height: 56px;

	cb-modal-close button {
		color: inherit;
		&:focus, &:focus-visible {
			outline: solid 2px var(--action-800) !important; // icon buttons have no focus, so this is to override that
			outline-offset: -1px !important;
		}
	}
}

.modal-body {
	background-color: var(--white);
}

.panel {
	background-color: var(--white);
}

.action-background, .bg-primary{
	@include action-color-background;
}

#primary-container, .main-content { background-color: var(--gray-200); }
.br-dashboard-empty-image { color: var(--custom-bg-primary-contrast, var(--default-text-color)); }


.action-color {
	color: var(--action-600) !important;
}

.br-brand-primary-background {
	@include primary-background;
}

.action-text, .action-text:hover, .action-text:focus,
.action-text > a, .action-text > a:hover, .action-text > a:focus,
.hover-parent:hover > .action-parent-hover-text { color:var(--active-text-hover-color) !important;}
.action-hover-text:hover, .action-hover-wrapper:hover .action-hover-text { color:var(--active-text-hover-color) !important;}
.action-hover-border:hover, .action-hover-wrapper:hover .action-hover-border { border-color:var(--action-600) !important;}
.action-background-only, .bg-primary { background-color:var(--action-600);}
.action-border, .action-border-hover:hover { border-color: var(--action-600);}

.cxs-table-row-hover:hover {
	color: var(--white) !important;
	background-color: var(--default-primary-color);
}

.invert {
	.action-color,
	.action-text, .action-text:hover, .action-text:focus,
	.action-text > a, .action-text > a:hover, .action-text > a:focus,
	.hover-parent:hover > .action-parent-hover-text { color:var(--white) !important;}
	.action-hover-text:hover, .action-hover-wrapper:hover .action-hover-text { color:var(--white) !important;}
	.action-hover-border:hover, .action-hover-wrapper:hover .action-hover-border { border-color:var(--white) !important;}
	.action-background-only { background-color:var(--white);}
	.action-border, .action-border-hover:hover { border-color:var(--white);}
}

.br-brand-border-four, .widgetTypeElement.ui-draggable-dragging, .action-border {
	@extend %brand-primary-border;
}


/* case management */
input[checked="checked"] + [uib-dropdown] > label:before { color: var(--default-primary-color); }

.br-brand-font-1 { color:var(--action-600); }

.fake-select .caret,
.option-chicklet .caret,
.dropdown-toggle .caret,
.br-organization-panel .caret,
.slick-sort-indicator {
	color: var(--default-text-color);
}

.hierarchy-item.selected, .searchable-hierarchy-list .selected > .hierarchy-item, .br-hierarchy-node[selected] > a,  .br-hierarchy-node[selected]>a>.br-hierarchy-node-name, .hierarchy-item:hover .action-color{
	@include action-color-background;
}

.hierarchy-item:hover, .hierarchy-item.focus-within, .node-hierarchy > li > a:hover, .node-hierarchy > li > a:hover > .br-hierarchy-node-name {
	@extend %brand-lighten-background;
}

.hierarchy-item:focus-within {
	@extend %brand-lighten-background;
	@extend %form-focus-shadow;
}

$toggle-switch-turned-off-bg: var(--gray-600);
$toggle-switch-turned-off-hover-bg: var(--gray-400);
$toggle-switch-turned-off-active-bg: var(--gray-900);
$toggle-switch-turned-off-focus-bg: $toggle-switch-turned-off-bg;
$toggle-switch-turned-off-disabled-bg: $toggle-switch-turned-off-bg;

.toggle-switch {
	> div { background: var(--action-800); }

	&:not(.disabled):hover > div { background: var(--active-text-hover-color); }
	&:active > div { background: $button-active-background-color; }
	&:focus { @extend %form-focus-shadow }

	&.disabled {
		> div, &:hover > div, &:active > div { opacity:.3; }

		.switch-off {
			&, &:hover, &:active, &:focus { background:$toggle-switch-turned-off-disabled-bg; }
		}
	}

	.invert & {
		> div { background: var(--white); }
		.knob { background: var(--action-800); }
	}

	> .switch-off {
		background: $toggle-switch-turned-off-bg;

		&:hover { background: $toggle-switch-turned-off-hover-bg; }
		&:focus { background: $toggle-switch-turned-off-focus-bg; }
		&:active { background: $toggle-switch-turned-off-active-bg; }
	}
}


.header-element:hover [ngbdropdowntoggle] .q-icon {
	color: var(--header-link-color-hover);
}

cb-account-switcher {
	&:hover a { color: var(--header-link-color-hover); }
	&:focus-within { color: var(--header-link-color-hover); }
}

.br-header {
	border-bottom-width: 1px;
	border-bottom-style: solid;

	.header-element, cb-account-switcher, cb-spaces {
		color: var(--header-link-color);
		border-color: var(--gray-300);
	}

	cb-account-switcher:hover a {
		color: var(--header-link-color-hover);
	}

	cb-account-switcher:focus-within {
		color: var(--header-link-color-hover);
	}

	// there are overlapping rulesets for .dropdown-toggle:focus
	#spaces-menu > div:first-child > a:first-of-type:focus {
		color: var(--header-link-color-hover) !important;
		outline-style: solid !important;
		outline-width: 2px  !important;
		outline-color: var(--focus-outline-color) !important;
	}

	.dropdown.show, .dropdown.open {
		background-color: var(--header-active-menu-bg);
		color: var(--header-link-color-hover, var(--white));
	}

	.header-link:focus, .dropdown-toggle:focus, button:focus {
		color: var(--header-link-color-hover);
		outline: solid 2px var(--focus-outline-color);
	}

	#sidebar-toggle:focus {
		color: var(--header-link-color-hover);
	}

	button {
		line-height: 1.3;
	}
}

#main-sidebar {
	--cxs-sidebar-bg: var(--white);

	background-color: var(--cxs-sidebar-bg);
	nav {
		background-color: var(--cxs-sidebar-bg) !important;
	}

	label, .text-white {
		color: var(--cxs-sidebar-color) !important;
	}

	#linkTextFilter {
		border-width: 1px !important;
	}

	.focus-dashed-white-outline:focus {
		outline: solid 2px var(--focus-outline-color) !important;
	}
}

.header-color-2 {
	background: var(--custom-bg-secondary, var(--white));
	color: var(--default-text-color);
	border-color: var(--black-50-percent);
}

label { color: inherit; }
a:hover { color: var(--active-text-hover-color); }

.accordion .card-header {
	.btn-link:hover {
		color: var(--active-text-hover-color);
	}
}

.cg-busy-default-sign {
	border-color: var(--white-50-percent);
	background-color: var(--gray-200);
	color: var(--default-text-color);
}

a.btn-icon:hover, a.btn-icon:focus, button.btn-icon:hover, button.btn-icon:focus {
	@extend %brand-primary-border;
	color: var(--default-primary-color);
}

.side-nav {
	&-item {
		&:hover { background: var(--default-primary-color)}
	}

	&-sub-item > a { color:var(--gray-400); }

	&-item.active > a:hover { color:var(--white); }
}

a.btn-icon:hover, a.btn-icon:focus, button.btn-icon:hover, button.btn-icon:focus, a.btn-icon-small:hover, a.btn-icon-small:focus,
.br-selected-metric .btn-icon-small.selected {
	@extend %brand-primary-border;
	color: var(--default-primary-color);
}

/* contextmenu */

#dsh-context-menu .selected>div>ul>li>.hierarchy-item,
#dsh-context-menu .selected>div>ul>li>.hierarchy-item>.br-hierarchy-node-toggle {
	background-color: var(--default-primary-color);
	color: var(--white);
}

context-menu .expandable:hover {
	@extend %brand-lighten-background;
}

.hierarchy-item {
	color: var(--default-text-color);
}

.gridster-preview-holder, .gridster-preview-line {
	border-color:var(--action-600);
	&::before { color:var(--action-600); }
}

.gridster-placeholder {
	border-color: var(--gray-1000);
	z-index: 0;
	&::before { color: var(--gray-1000); }
}

.br-about-image {
	background-color: var(--gray-1000)
}

tags-input .autocomplete {
	& .suggestion-item {
		&.selected {
			&, & em { @extend %brand-lighten-background; }
		}
	}
}

#dsh-context-menu>li>a:hover,
#dsh-context-menu>li>a:focus,
#dsh-context-menu .open-menu>a, .action-hover:hover  {
	@extend %brand-lighten-background;
}

.mention-link {
	color: var(--gray-900) !important;
	background: var(--white) !important;
}

.nav-pills>li {
	&.active a {
		&, &:focus, &:hover { @include action-color-background; }
	}

	&:not(.read-only) a:not(.disabled) {
		&:focus, &:hover { @include action-color-background; }
	}

	& > a.disabled {
		color: var(--gray-400);
		&:hover {
			background-color: var(--gray-1000);
		}
	}

	&.selected {
		> a, > label {
			&, &:hover, &:focus {
				@include action-color-background;
				fill: var(--gray-200);
				font-weight: normal;
			}
		}
		> label a {
			color: var(--default-text-color);
		}
	}

	&.read-only > a {
		&, &:hover, &:focus {
			background-color: var(--default-text-color);
		}
	}
}

.dropdown-menu {
	&, li>span, li>a, li>button, li>div>.filter-child, .dropdown-item {
		background-color: var(--white);
		color: var(--default-text-color);
	}
	.danger-menu-item {
		color: var(--danger-600) !important;
	}
}

.text-menu-color {
	color: var(--default-text-color);
}
.bg-menu-color {
	background-color: var(--white);
}

.dropdown-menu:not(.disabled):not(.read-only) {
	li, .active, li>div {
		>button:hover, >a:hover, >a.focus-within, >span.br-hierarchy-node-toggle:focus, >a:focus-within, div:focus > .dropdown-item {
			@extend %brand-lighten-background;

			path { stroke: var(--gray-900); }
		}
	}

	.dropdown-item {
		&:hover, &:focus {
			@extend %brand-lighten-background;
		}
	}
	.danger-menu-item {
		&:hover, &:focus {
			color: var(--white) !important;
			background-color: var(--danger-600) !important;

		}
	}

	.active {
		>a, >.dropdown-item, &.dropdown-item { @extend %brand-lighten-background; }
	}
}

.dropdown-menu.disabled, .dropdown-menu.read-only {
	li, .active, li>div {
		>a:hover, >a:focus { color: inherit; background: inherit; }
	}

	.active>a { color: inherit; background: inherit; }
}

.dropdown-input .dropdown-toggle {
	background-color: var(--white);
	color: var(--default-text-color);
	border: 1px solid var(--gray-500);
}

.dropdown-header {
	background-color: var(--gray-200);
	color: var(--default-text-color);
}

tags-input .tags.focused { @extend %brand-primary-border }

textarea, input, select, .br-color-dropdown button, .dropdown.fake-select button:not(.ignore-dropdown-style),
.form-control, .input, .fake-select > button:not(.ignore-dropdown-style), .dropdown-input.dropdown, .discussion-submission {
	background-color: var(--white);
	color: var(--default-text-color);

	&::placeholder {
		@extend %brand-primary-border;

	}
	&:focus{
		@extend %brand-primary-border;
		@extend %form-focus-shadow;
	}
}

input:not([type="checkbox"]):not([type="radio"]) {
	background-color: var(--white);
}


.dropdown-menu .no-hover {
	&, > a {
		color: var(--gray-900) !important;
		background-color: var(--white) !important;
	}
}


.dropdown-input.option-chicklet.open { @extend %brand-primary-border }

.dropdown-input {
	&.dropdown {
		a[uib-dropdown-toggle] {
			background-color: var(--gray-1000);
			padding: 8px;
		}
	}
}

.select2-container-active {
	.select2-choice, .select2-choices { @extend %brand-primary-border; }
}

.table tr,
.cases-table tr,
.br-grid .slick-row,
.br-log-grid .slick-row {
	@extend %branded-row-hover;

	.toggle-switch-animate {
		&.switch-on {
			background-color: var(--action-600);
			.knob {
				background-color: var(--gray-200);
			}
		}
		&.switch-off {
			background-color: var(--gray-200);
			.knob {
				background-color: var(--gray-100);
			}
		}
	}

}

%branded-widget-table-row-focus {
	&:focus {
		background-color: var(--action-200);
		color: var(--default-text-color);
	}
}

%branded-widget-table-row-hover {
	&:hover, &.hover {
		background-color: var(--action-200);
		color: var(--default-text-color);
	}
}

.br-widget-content .slick-row,
.cb-preview-table tr,
.br-widget-content .slick-header-column,
.preview-tabe-view .slick-header-column {
	& { @extend %branded-widget-table-row-hover; }
	.point-selection &.active {
		color: var(--white);
		background-color: var(--default-primary-color);
	}

	background-color: var(--white);
	&.alternate, &.odd:not(.ignore-odd):not(.slick-parent-row) {
		background-color: var(--gray-100);
		color: var(--default-text-color);
		&:hover, &.hover {
			background-color: var(--action-200);
		}
	}
}

.widget-tile {
	color: var(--gray-1000);
	&:hover {
		color: var(--action-800);
		background-color: var(--action-100);
	}
	&.ui-draggable-dragging {
		color: var(--action-800);
		background-color: var(--action-100);
	}
	&:active {
		color: var(--action-900);
	}

	background-color: var(--white);
}

.toolbar-drop-area {
	background-color: var(--action-300);

	&.drag-enter { border-color: var(--default-primary-color); }
}

.selected .slick-cell, .slick-cell.selected {
	background-color: var(--default-primary-color);
	border-color: var(--white);
	color: var(--white);
}

.selected:hover > .action-parent-hover-text { color: var(--white) !important; }

.disabled {
	.action-hover-text:hover, .action-hover-wrapper:hover .action-hover-text { color: inherit !important }
}

.dashboard-tab .btn .tab-action { color: getContrastColorLighten($cxs-brand-secondary-background);	}

.brand-secondary-bg-fade-start .fade-container:before {
	background: linear-gradient(270deg, transparent, $cxs-brand-secondary-background);
}
.brand-secondary-bg-fade-end .fade-container:after {
	background: linear-gradient(90deg, transparent, $cxs-brand-secondary-background);
}

.nf-list-group {
	li a {
		@include default-link-style;
	}
}

.palette-color.open {
	@extend %form-focus-shadow;
}

selector-widget-button > div:focus {
	border-color: var(--default-primary-color);
	box-shadow: 0 0 5px var(--action-600);
}

.cx-add-widget-toolbar {
	background-color: var(--white);
}

empty-content {
	background-color: var(--white);

	.q-icon {
		color: var(--gray-400);
	}
}

.pin-slick-header item-grid .slick-header {
	background-color: var(--white);
}

.br-widget-content {
	.slick-header-column {
		background: var(--white);

		& { @extend %branded-widget-table-row-hover; }
		& { @extend %branded-widget-table-row-focus; }

		&:hover,&-active {
			background: var(--gray-200);
		}
	}

	.slick-headerrow { background: var(--gray-200); }

	.slick-headerrow-column {
		background: var(--gray-200);
	}

	.slick-row {
		background: var(--white);
		@extend %branded-widget-table-row-hover;

		&.ui-state-active { background: var(--gray-200); }
	}

	.slick-cell.selected {
		background-color: var(--gray-200);
	}

	.slick-sortable-placeholder { background: var(--gray-400) !important; }
}

.br-show-total {
	.slick-row {
		@extend %branded-widget-table-row-hover;

		&.slick-leaf-row {
			background-color: var(--white);
			color: var(--default-text-color);;

			&:hover {
				background-color: var(--default-text-color);
				color: var(--action-200);
			}
		}

		&.slick-row-total {
			background-color: var(--default-text-color);
			color: var(--white);

			&:hover {
				background-color: var(--action-200);
				color: var(--default-text-color);
			}
		}

		&.slick-row-level-0, &.alternate.slick-row-level-0, &.odd.slick-row-level-0 { background-color:var(--gray-400); color: var(--default-text-color); }
		&.slick-row-level-1, &.alternate.slick-row-level-1, &.odd.slick-row-level-1 { background-color:var(--gray-400); color: var(--default-text-color); }
		&.slick-row-level-2, &.alternate.slick-row-level-2, &.odd.slick-row-level-2 { background-color:var(--gray-600); color: var(--white); }
		&.slick-row-level-3, &.alternate.slick-row-level-3, &.odd.slick-row-level-3 { background-color:var(--gray-600); color: var(--white); }

		&:hover, &.odd:hover, &.even:hover, &.hover {
			background-color: var(--action-200);
			color: var(--default-text-color);
		}
	}
}

%metric-widget-text {
	fill: var(--gray-900);
}

%metric-widget-line {
	stroke: var(--gray-900);
}

.single-metric-chart {
	&, & .metric-label {
		@extend %metric-widget-text;
	}
}

.metric-period-label, .metric-change-labels {
	@extend %metric-widget-text;
}

.metric-change-line {
	@extend %metric-widget-line;
}

.highcharts-legend-item-hidden {
	.highcharts-point {
		fill: var(--gray-400) !important;
		stroke: var(--gray-900) !important;

		&:focus {
			@extend %form-focus-shadow;
		}
		color: var(--gray-900) !important;
	}
	text {
		fill: var(--gray-900) !important;
	}
}

.cloud-selection-table {
	tr {
		background-color: var(--white);
		color: var(--default-text-color);
	}

	tbody tr.odd {
		background-color: var(--gray-200);
		color: var(--default-text-color);
	}

	tbody tr:hover {
		background-color: var(--action-200);
	}
}

.br-document-preview-attribute {
	background-color: var(--white);
}

.bubble {
	border: 1px solid;
	border-color: var(--gray-400);
}

.arrow-down-border {
	border-top-color: var(--gray-600);
}

.feedback-preview-bubble-item .arrow-down {
	border-color: var(--white) transparent transparent transparent;
}

.feedback-preview-source-icon {
	height: 40px;
	width: 40px;
}

.arrow-down, .arrow-down-border {
	border-color: var(--gray-400) transparent transparent transparent;
}

.br-interaction-explorer-grid .label-bubble {
	background-color: transparent;
}


// Selector widget doesn't have footer, so we need to round out the bottom
.br-widget-box.SELECTOR_BUTTON {
	.br-widget-content {
		border-bottom-left-radius: var(--widget-border-radius);
		border-bottom-right-radius: var(--widget-border-radius);
	}
}
document-previewer, .cb-preview-table-container, tuning-suggestion-summary {
	background-color: var(--gray-300);
	.dex-list-group {
		background-color: var(--white);
	}

	.dex-list-group li.selected {
		background-color: var(--white);
	}

	.label-bubble {
		background-color: transparent;
		color: var(--default-text-color);
		&.selected {
			background: var(--gray-200);
		}
		&.disabled {
			opacity: 0.7;
		}
	}

	tabbed-context-pane .label-bubble {
		&.selected {
			color: var(--blue-100);
			background-color: var(--blue-800);
		}
	}

	.dex-model-attribute {
		.label-bubble { background: var(--gray-400); }
	}

	.doc-explorer-panel {
		.panel-heading {
			color: var(--gray-1000);
			background: var(--gray-200);
		}
	}

	.dex-left-pane {
		background-color: var(--white);
		&:focus{
			@extend %form-focus-shadow
		}
	}
}

.panel-body {
	color: var(--default-text-color);
}

.br-versions-panel {
	.nav-pills > li:not(.selected) > label {
		color: var(--default-text-color);
	}
}

.an-document-explorer {
	.dx-filters .btn-selected { color: var(--white) !important; }
}

.sentence-context {
	color: var(--default-text-color);
}

.explorer-tools {
	background-color: var(--white);
	.slim-dropdown li:hover { background-color: var(--gray-400); }
}

a[disabled] {
	color: var(--gray-900) !important;
}

.list-group-item {
	background-color: var(--gray-200);
}

.twitter-container {
	.twitter-metadata span, .twitter-timestamp, .twitter-metadata, .twitter-creator {
		color: var(--default-text-color);
	}
}

.gridster-widget.selected {
	outline: 2px solid var(--gray-1000) !important;
	border-radius: var(--widget-border-radius);
}

.br-widget-placeholder {
	background-color: var(--white);
}

.cases-table.table {
	tr:hover, tr.even:hover, tr.odd:hover {
		&>td, &>th {
			background-color: var(--action-200);
			color: var(--default-text-color);
		}
	}
}

.br-grid, .br-user-row {
	.slick-header-columns {
		color: var(--gray-1000);
	}
}

.rounded-grouping-label, .labeled-rule label {
	background-color: var(--white);
}


.icon-highlight:not(.feedback-preview-source-icon) {
	background: var(--white);

	&.placeholder { background: transparent; }
}

$directional-positive: var(--pos-sentiment-600);
$directional-negative: var(--neg-sentiment-600);
$directional-easy-effort: var(--effort-easy-600);
$directional-hard-effort: var(--effort-hard-600);

.positive-change-color {
	fill: $directional-positive;
	color: $directional-positive;
}
.negative-change-color {
	fill: $directional-negative;
	color: $directional-negative;
}
.neutral-change-color { fill: var(--gray-600) }
.positive-effort-change-color {
	fill: $directional-easy-effort;
	color: $directional-easy-effort;
}
.negative-effort-change-color {
	fill: $directional-hard-effort;
	color: $directional-hard-effort;
}

.multiselect {
	&.paging {
		.new-value-dot {
			background-color: var(--default-primary-color);
		}
	}
}

// conversation

// conversation .cxs-brand-primary { color: var(--default-primary-color); }

.panel .panel-body.main-panel {
	background-color: var(--white);
}

spine-header {
	background-color: var(--gray-200);
}

.spine-update .silence {
	color: var(--default-text-color);
}

.spine-wrapper {
	background-color: var(--white);
}

.conversation-segment {
	&.is-highlighted, &.is-adjacent {
		.sentence-container {
			border-color: var(--blue-700);
		}
	}
}

.is-overtalk {
	$overtalk-background-color: var(--warning-200);
}


.tooltip-content.channel-bot, .conversation-segment.bot-transcript .sentence-text-container {
	color: var(--aqua-900);
}

.spine-update {
	.mejs__time {
		color: var(--default-text-color);
	}
	.mejs__time-handle {
		background-color: var(--action-600);
	}
	.mejs__horizontal-volume-total {
		background-color: var(--black-50-percent);
	}
	.mejs__horizontal-volume-current {
		background-color: var(--white-50-percent);
	}
}


cb-text-editor #text-editor .text-wrapper {
	background-color: var(--white);
	color: var(--gray-900);
	pre {
		background-color: var(--gray-200);
		color: var(--gray-900);
	}
}

.cb-spine-row.selected, .cb-spine-row.selected.topicHighlight {
	stroke: var(--blue-400);
}
.cb-spine-row.topicHighlight {
	stroke: var(--blue-700);
}

.explorer-tools.audit-mode, .br-widget-header.audit-mode {
	background-color: var(--default-primary-color);
	color: var(--white);
	button {
		color: inherit;
	}
}

.interaction-audit-toggle, .intelligent-scoring-toggle {
	color: var(--default-text-color);

	&:hover {
		color: var(--default-primary-color);
	}

	&:disabled, &:disabled:hover {
		color: var(--default-text-color);
	}
}

.audit-mode .interaction-audit-toggle, .audit-mode .intelligent-scoring-toggle, #dashboardContainer .br-widget-header.audit-mode  a {
	background-color: var(--default-primary-color);
	color: var(--white) !important;
	&:hover {
		color: var(--gray-900) !important;
	}
}

.audit-mode .br-sentence-text:hover span, .audit-mode .hover .br-sentence-text span,
.audit-mode .sentence-preview-hover, .audit-mode .voice-sentence-hover .br-sentence-text span {
	background-color: var(--action-300);
}

.interaction-audit-title {
	background-color: var(--default-primary-color);
	color: var(--white);
}

jump-link {
	:hover {
		color: var(--gray-1000);
		background-color: var(--action-200);
	}
}

.audit-mode speech-bubble article p {
	@extend %branded-row-hover;
	cursor: pointer;
}

@mixin function-color {
	color: var(--warning-600);
}
@mixin attribute-color {
	color: var(--gray-600);
}
@mixin aggregation-color {
	color: var(--emotional-intensity-700);
}
@mixin predefined-metric-color {
	color: var(--action-600);
}
@mixin custom-metric-color {
	color: var(--emotional-intensity-1000);
}
@mixin scorecard-metric-color {
	color: var(--action-600);
}
@mixin hierarchy-metric-color {
	color: var(--success-600);
}

.formula-input {
	background-color: var(--gray-200);
	color: var(--default-text-color);

	.math-function { @include function-color(); }
	.math-attribute { @include attribute-color(); }
	.math-aggregation { @include aggregation-color(); }
	.math-predefined-metric { @include predefined-metric-color(); }
	.math-custom-metric { @include custom-metric-color(); }
	.math-scorecard-metric { @include scorecard-metric-color(); }
	.math-hierarchy-metric { @include hierarchy-metric-color() }
}

.dropdown-menu:not(.disabled):not(.read-only) a {
	&.math-function { @include function-color(); }
	&.math-attribute { @include attribute-color(); }
	&.math-aggregation { @include aggregation-color(); }
	&.math-predefined-metric { @include predefined-metric-color(); }
	&.math-custom-metric {@include custom-metric-color(); }
	&.math-scorecard-metric { @include scorecard-metric-color(); }
	&.math-hierarchy-metric { @include hierarchy-metric-color() }
}


.interaction-filter {
	.filter-builder-header {
		background: var(--gray-200);
	}

	.saved-filters {
		border-left: 1px solid var(--gray-200);
	}
}

#quick-search-panel {
	background-color: var(--white);
}

.math-object-tooltip {
	color: var(--default-text-color);
	background-color: var(--white);
}

.math-object-tooltip:before {
	border-bottom-color: var(--white);
}

.bg-white {background-color: var(--white);}
.bg-gray-200 {background-color: var(--gray-200);}
.bg-gray-400 {background-color: var(--gray-400);}
.border-gray-200 {border-color: var(--gray-200);}
.border-gray-600 {border-color: var(--gray-600);}
.border-gray-900 {border-color: var(--gray-900);}

.rows-delimiter {
	border-bottom: 1px solid var(--bg-color-client);
}
.rows-delimiter-top {
	border-top: 1px solid var(--bg-color-client);
}

input[type=checkbox], input[type=radio] {
	accent-color: var(--action-800);
}

.reference-line-builder-dropdown {
	plot-line-preview path {
		stroke: var(--gray-1000) !important;
	}
	a:hover {
		background-color: var(--action-400) !important;
		plot-line-preview path {
			stroke: var(--gray-1000) !important;
		}
	}
	.selected a {
		background-color: var(--action-900) !important;
		plot-line-preview path {
			stroke: var(--gray-200) !important;
		}
	}
}

.q-tabs {
	> .active::before,
	> li:hover::before { background: var(--action-1000) !important; }
}

// new branding
.bg-dashboard,
.bg-dashboard\:hover:hover { background: var(--custom-bg-primary, var(--gray-100)) !important}

.bg-main,
.bg-main\:hover:hover { background: var(--custom-bg-primary, var(--gray-100)) !important}

real-data-preview.no-background, report-view-wrapper.no-background {
	#real-data-preview, .chart-demo, .br-widget-view, .cg-busy-backdrop {
		background: var(--dashboard-bg) !important;
	}
}

.bg-secondary,
.bg-secondary\:hover:hover { background: var(--custom-bg-secondary, var(--white)) !important}


:root{
	--cxs-brand-header-bg: var(--custom-bg-header, var(--white));
}
