$gray: var(--gray-900);
$orange: var(--warning-600);
$dark-popup-shadow: 0 0 8px 0 var(--black-50-percent);


$primary-color-background: var(--gray-200);
$cxs-brand-secondary-background: var(--white);
$cxs-brand-header-background: var(--gray-900);

$primary-color-UPDATED-100: var(--action-100);
$primary-color-UPDATED-200: var(--action-200);
$primary-color-UPDATED-300: var(--action-300);
$primary-color-UPDATED-400: var(--action-400);
$primary-color-UPDATED-500: var(--action-500);
$primary-color-UPDATED-600: var(--action-600);
$primary-color-UPDATED-700: var(--action-700);
$primary-color-UPDATED-800: var(--action-800);
$primary-color-UPDATED-900: var(--action-900);
$primary-color-UPDATED-1000: var(--action-1000);

// css vars
:root {
	--black-50-percent: #{$black-50-percent};
	--white-50-percent: #{$white-50-percent};

	// aliases
	--default-text-color: var(--gray-1000);
	--default-primary-color: var(--action-700);
	--active-text-hover-color: var(--action-600);

	--loading-spinner-background: var(--white);
}
